/**
 * @generated SignedSource<<b79545e4832555a3b29d79e6a19745a3>>
 * @relayHash 04ce88e27f5d1ebf4012d004476c2b34
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-admin-accounts/491.0.0-2024-12-09T17:23:08.990Z/internalForgotPasswordMutation

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RequestPasswordEmailInput = {
  clientMutationId?: string | null;
  email: string;
  urlRedirect?: string | null;
};
export type internalForgotPasswordMutation$variables = {
  input: RequestPasswordEmailInput;
};
export type internalForgotPasswordMutation$data = {
  readonly requestPasswordEmail: {
    readonly __typename: "RequestPasswordEmailPayload";
  } | null;
};
export type internalForgotPasswordMutation = {
  response: internalForgotPasswordMutation$data;
  variables: internalForgotPasswordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RequestPasswordEmailPayload",
    "kind": "LinkedField",
    "name": "requestPasswordEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "internalForgotPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "internalForgotPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "app-admin-accounts/491.0.0-2024-12-09T17:23:08.990Z/internalForgotPasswordMutation",
    "metadata": {},
    "name": "internalForgotPasswordMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "bcde539132dabbb8c68ff7a3337e0a24";

export default node;
